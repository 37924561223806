import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ProductType } from '../../../graphql/__generated__/globalTypes'
import { GetProducts_getProducts } from '../../../graphql/queries/__generated__/GetProducts'
import { UpdateProduct as Update, UpdateProductVariables } from '../../../graphql/mutations/__generated__/UpdateProduct'
import { useMutation } from '@apollo/client'
import { updateProdut } from '../../../graphql/mutations'
import { LoadingOverlay } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import _ from 'lodash'

type Props = {
    product: GetProducts_getProducts
}

const UpdateProduct = ({ product }: Props) => {
    const [execute, { loading }] = useMutation<Update, UpdateProductVariables>(updateProdut, {
        refetchQueries: ["GetProducts"]
    })
    const { register, handleSubmit } = useForm<UpdateProductVariables>({
        defaultValues: {
            input: {
                ..._.omit(product, "__typename")
            }
        }
    });


    const onSubmit: SubmitHandler<UpdateProductVariables> = variables => {
        execute({ variables }).then(res => {
            showNotification({
                message: "Product updated successfully",
                title: "Success",
                color: "teal"
            })
        }).catch(err => {
            showNotification({
                message: err?.message,
                title: "Error",
                color: "red"
            })
        });
    }

    return (
        <form className='relative' onSubmit={handleSubmit(onSubmit)}>
            <LoadingOverlay visible={loading} title='Updating Product' />
            <div className="grid grid-cols-2 gap-4">
                {/* Product Name */}
                <div className="">
                    <label
                        htmlFor="first-name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Product name
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            {...register("input.name", { required: true })}
                            id="first-name"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                {/* Type of Product for Pricing */}
                <div className="">
                    <label
                        htmlFor="first-name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Product type
                    </label>
                    <div className="mt-2">
                        <select
                            {...register("input.type")}
                            id="first-name"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                            <option value={ProductType["price"]}>Price</option>
                            <option value={ProductType["quantity"]}>Quantity</option>
                        </select>
                    </div>
                </div>
                {/* Price */}
                <div className="col-span-2">
                    <label
                        htmlFor="first-name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Product price / Starting Price
                    </label>
                    <div className="mt-2">
                        <input
                            type="number"
                            {...register("input.price", {
                                required: true,
                                valueAsNumber: true,
                            })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
            </div>
            <div className="mt-5 w-full">
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white w-full shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Update Product
                </button>
            </div>
        </form>
    )
}

export default UpdateProduct