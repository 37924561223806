import { gql } from "@apollo/client";

export const createProduct = gql`
  mutation CreateProduct($input: ProductInput!) {
    createProduct(input: $input)
  }
`;

export const loginMutation = gql`
  mutation LoginAdmin($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password) {
      token
      admin {
        id
        name
        email
        role
      }
    }
  }
`;

export const updateProdut = gql`
  mutation UpdateProduct($input: ProductInput!) {
    updateProduct(input: $input)
  }
`;
