import React from "react";
import Screen from "../../components/Screen/Screen";

type Props = {};

const DeliveryZones = (props: Props) => {
  return <Screen>DeliveryZones</Screen>;
};

export default DeliveryZones;
