import { useState } from "react";
import { storage } from "../config/firebase";
import {
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from "firebase/storage";

export const useUploadMedia = (title?: string) => {
    const [url, setUploadingMedia] = useState<string>("");
    const [loading, setLoading] = useState(false);

    const onFileChange = ({ target }: any) => {
        console.log(target)
        const storageRef = ref(storage, "images/" + title);
        const uploadTask = uploadBytesResumable(storageRef, target.files[0]);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setLoading(percent < 100);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log(url);
                    setUploadingMedia(url);
                });
            }
        );
    };

    const deleteFile = () => {
        setUploadingMedia("");
        setLoading(true);
        deleteObject(ref(storage, "images/" + title))
            .then(() => {
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    return {
        loading,
        onFileChange,
        deleteFile,
        url,
    };
};

export default useUploadMedia;