import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { BASE_URL } from "../constants";

export const TOKEN_str = "grocery-hub-admin";

const httpLink = createHttpLink({
  uri: BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //   const token = store.getState().user.token;
  //   const country = store.getState().app.country;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      //   authorization: token ? token : "",
      //   country: country ? country : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export { client };
