import { PhotoIcon } from "@heroicons/react/20/solid";
import { LoadingOverlay } from "@mantine/core";
import { useEffect, useRef } from "react";
import useFileUpload from "../../hooks/useFileUpload";
import { faker } from "@faker-js/faker";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  CreateProduct,
  CreateProductVariables,
} from "../../graphql/mutations/__generated__/CreateProduct";
import { ProductType } from "../../graphql/__generated__/globalTypes";
import { useMutation } from "@apollo/client";
import { createProduct } from "../../graphql/mutations";
import { notifications } from "@mantine/notifications";

type Props = {
  setShow: any;
};

const AddProduct = ({ setShow }: Props) => {
  const ref = useRef<any>();
  const { onFileChange, url, loading } = useFileUpload(faker.datatype.uuid());
  const { register, handleSubmit, setValue } =
    useForm<CreateProductVariables>();

  const [create, { loading: creating }] = useMutation<
    CreateProduct,
    CreateProductVariables
  >(createProduct, {
    refetchQueries: ["GetProducts"],
  });

  const onSubmit: SubmitHandler<CreateProductVariables> = (data) => {
    console.log(data);
    create({ variables: data })
      .then((res) => {
        notifications.show({
          message: "Product added Successfully",
          color: "teal",
        });
        setShow();
      })
      .catch((err) => {
        notifications.show({
          message: "Product could not be added Successfully",
          color: "red",
        });
      });
  };

  useEffect(() => {
    if (url) {
      setValue("input.image", url);
    }
  }, [url]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay
        visible={loading || creating}
        about={loading ? "uploading image" : "Creating Product. Hang on"}
      />
      <div className="col-span-full">
        <label
          htmlFor="cover-photo"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Cover photo
        </label>

        <div
          onClick={() => ref?.current?.click()}
          className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
        >
          <div className="text-center">
            {url ? (
              <img src={url} className="h-20 w-20 mx-auto" alt="item" />
            ) : (
              <PhotoIcon
                className="mx-auto h-12 w-12 text-gray-300"
                aria-hidden="true"
              />
            )}

            <div className="mt-4 flex text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
              >
                <span>Upload a file</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  ref={ref}
                  onChange={onFileChange}
                  className="sr-only"
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">
              PNG, JPG, GIF up to 10MB
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-10">
        {/* Product Name */}
        <div className="">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Product name
          </label>
          <div className="mt-2">
            <input
              type="text"
              {...register("input.name", { required: true })}
              id="first-name"
              autoComplete="given-name"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {/* Type of Product for Pricing */}
        <div className="">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Product type
          </label>
          <div className="mt-2">
            <select
              {...register("input.type")}
              id="first-name"
              autoComplete="given-name"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option value={ProductType["price"]}>Price</option>
              <option value={ProductType["quantity"]}>Quantity</option>
            </select>
          </div>
        </div>
        {/* Price */}
        <div className="col-span-2">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Product price / Starting Price
          </label>
          <div className="mt-2">
            <input
              type="number"
              {...register("input.price", {
                required: true,
                valueAsNumber: true,
              })}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
      <div className="mt-5 w-full">
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white w-full shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Add Product
        </button>
      </div>
    </form>
  );
};

export default AddProduct;
