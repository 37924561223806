import { Fragment } from 'react'
import { Menu, Modal } from '@mantine/core';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { useDisclosure } from '@mantine/hooks';
import { GetProducts_getProducts } from '../../graphql/queries/__generated__/GetProducts';
import UpdateProduct from '../../pages/Products/components/UpdateProduct';

type Props = {
    product: GetProducts_getProducts
}

const ProductsAction = ({ product }: Props) => {
    const [opened, { close, open }] = useDisclosure(false)
    return (
        <Fragment>
            <Menu shadow="md" width={200}>
                <Menu.Target>
                    <button className=''>
                        <EllipsisHorizontalIcon className='h-4 w-11' />
                    </button>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item onClick={open}>Edit product</Menu.Item>
                    <Menu.Item color="red">Delete product</Menu.Item>
                </Menu.Dropdown>
            </Menu>


            <Modal onClose={close} title={`Update ${product?.name}`} opened={opened}>
                <UpdateProduct product={product} />
            </Modal>

        </Fragment>
    )
}

export default ProductsAction