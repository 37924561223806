import { SubmitHandler, useForm } from "react-hook-form";
import {
  LoginAdmin,
  LoginAdminVariables,
} from "../graphql/mutations/__generated__/LoginAdmin";
import { useMutation } from "@apollo/client";
import { loginMutation } from "../graphql/mutations";
import { LoadingOverlay } from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppDispatch } from "../app/hooks";
import { authenticate } from "../features/auth";
import { useNavigate } from "react-router-dom";

export default function AuthLayout() {
  const { register, handleSubmit } = useForm<LoginAdminVariables>();
  const [login] = useMutation<LoginAdmin, LoginAdminVariables>(loginMutation);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<LoginAdminVariables> = (variables) => {
    showNotification({
      id: "login",
      loading: true,
      title: "Logging in",
      message: "Data will be loaded in 3 seconds, you cannot close this yet",
      autoClose: false,
    });
    login({ variables })
      .then((res) => {
        dispatch(authenticate(res.data?.loginAdmin as any));
        navigate("/", { replace: true });
        updateNotification({
          id: "login",
          title: "Success",
          message: "Login Successful",
          color: "blue",
        });
      })
      .catch((err) => {
        updateNotification({
          id: "login",
          title: "Error",
          message: err?.message,
          color: "red",
        });
      });
  };

  return (
    <>
      {/* <LoadingOverlay visible={loading} /> */}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-6"
            action="#"
            method="POST"
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  {...register("email", { required: true })}
                  type="email"
                  autoComplete="email"
                  placeholder="eg. ebaidoo79@gmail.com"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  {...register("password", { required: true })}
                  type="password"
                  placeholder="eg. *************"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
