import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"
import { getStorage } from "@firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyDGTF9OggnrTKSoc10nAzPWmtUU2fXSObw",
    authDomain: "ecole-dashboard.firebaseapp.com",
    databaseURL: "https://ecole-dashboard.firebaseio.com",
    projectId: "ecole-dashboard",
    storageBucket: "ecole-dashboard.appspot.com",
    messagingSenderId: "746554442113",
    appId: "1:746554442113:web:3ee319fff78945b942e880",
    measurementId: "G-B1WHEHZQMY"
};

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storage = getStorage(app);