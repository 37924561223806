import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from "./routes";
import { LoadingOverlay, MantineProvider } from "@mantine/core";
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/client";
import { Notifications } from "@mantine/notifications";

const router = createBrowserRouter(routes);

function App() {
  return (
    <ApolloProvider client={client}>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Notifications position="top-left" />
        <RouterProvider
          router={router}
          fallbackElement={
            <LoadingOverlay visible className="h-screen w-screen" />
          }
        />
      </MantineProvider>
    </ApolloProvider>
  );
}

export default App;
