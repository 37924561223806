import { PlusSmallIcon } from "@heroicons/react/20/solid";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { Fragment } from "react";

type Props = {};

const AddStaff = (props: Props) => {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Fragment>
      <button
        onClick={open}
        className="ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
        Add Staff Member
      </button>

      <Modal
        size="xl"
        title="Add Employee"
        opened={opened}
        onClose={close}
      ></Modal>
    </Fragment>
  );
};

export default AddStaff;
