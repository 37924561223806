import React from "react";
import Screen from "../../components/Screen/Screen";

type Props = {};

const Expenses = (props: Props) => {
  return <Screen>Expenses</Screen>;
};

export default Expenses;
