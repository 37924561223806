import { useQuery } from "@apollo/client";
import {
  GetOrders,
  GetOrdersVariables,
} from "../../../graphql/queries/__generated__/GetOrders";
import { getOrders } from "../../../graphql/queries";

export const useOrders = (variables?: GetOrdersVariables) => {
  const { data, loading, error } = useQuery<GetOrders, GetOrdersVariables>(
    getOrders,
    {
      variables,
    }
  );

  return {
    orders: data?.getOrders ?? [],
    loading,
    error,
  };
};
