/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ProductType {
  price = "price",
  quantity = "quantity",
}

export interface IdOperator {
  eq?: string | null;
  in?: string[] | null;
  notIn?: string[] | null;
}

export interface OrderFilter {
  id?: IdOperator | null;
}

export interface Pagination {
  offset?: number | null;
  limit?: number | null;
}

export interface ProductFilter {
  id?: IdOperator | null;
  name?: StringOperator | null;
}

export interface ProductInput {
  id?: string | null;
  name: string;
  image: string;
  type: ProductType;
  price: number;
}

export interface StringOperator {
  eq?: string | null;
  contains?: (string | null)[] | null;
  in?: string[] | null;
  notIn?: string[] | null;
  regex?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
