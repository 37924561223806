import React from "react";
import Screen from "../../components/Screen/Screen";

type Props = {};

const Clients = (props: Props) => {
  return <Screen>Clients</Screen>;
};

export default Clients;
