import { gql } from "@apollo/client";

export const getProducts = gql`
  query GetProducts($filter: ProductFilter, $pagination: Pagination) {
    getProducts(filter: $filter, pagination: $pagination) {
      id
      name
      image
      type
      price
    }
  }
`;

export const getOrders = gql`
  query GetOrders($filter: OrderFilter, $pagination: Pagination) {
    getOrders(filter: $filter, pagination: $pagination) {
      id
      total
      delivery {
        id
        zone
        estate
        suite
      }
      customerName
      customerPhone
      items {
        id
        amount
        quantity
        product {
          id
          name
          image
          type
          price
        }
      }
    }
  }
`;
