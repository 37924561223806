import { RouteObject } from "react-router-dom";
import Admin from "./layouts/Admin";
import Dashboard from "./pages/Dashboard";
import Products from "./pages/Products";
import Clients from "./pages/Clients";
import Expenses from "./pages/Expenses";
import DeliveryZones from "./pages/DeliveryZones";
import AuthLayout from "./layouts/Auth";
import Staff from "./pages/Staff";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Admin />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "products",
        element: <Products />,
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "expenses",
        element: <Expenses />,
      },
      {
        path: "zones",
        element: <DeliveryZones />,
      },
      {
        path: "staff",
        element: <Staff />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
  },
];
