import { useQuery } from "@apollo/client";
import {
  GetProducts,
  GetProductsVariables,
} from "../../../graphql/queries/__generated__/GetProducts";
import { getProducts } from "../../../graphql/queries";

export const useProducts = (variables?: GetProductsVariables) => {
  const { data, loading, error } = useQuery<GetProducts, GetProductsVariables>(
    getProducts,
    {
      variables,
    }
  );

  return {
    products: data?.getProducts ?? [],
    loading,
    error,
  };
};
