import { PlusSmallIcon } from "@heroicons/react/20/solid";
import React, { PropsWithChildren } from "react";

type Props = {
  title?: string;
  actionButton?: React.ReactNode | React.ReactNode[];
};

const secondaryNavigation: any[] = [];

const Screen = ({
  children,
  title,
  actionButton,
}: PropsWithChildren<Props>) => {
  return (
    <>
      <div className="relative isolate overflow-hidden ">
        <header className="pb-4 pt-6 sm:pb-6">
          <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
            <h1 className="text-base font-semibold leading-7 text-gray-900">
              {title}
            </h1>
            <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
              {secondaryNavigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={item.current ? "text-indigo-600" : "text-gray-700"}
                >
                  {item.name}
                </a>
              ))}
            </div>
            <>{actionButton}</>
          </div>
        </header>
      </div>
      <div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">{children}</div>
    </>
  );
};

export default Screen;
