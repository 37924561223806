import { PlusSmallIcon } from "@heroicons/react/20/solid";
import React from "react";
import AddProduct from "../AddProduct/AddProduct";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";

type Props = {};

const AddProductButton = (props: Props) => {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <>
      <button
        onClick={open}
        className="ml-auto flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
        New Product
      </button>

      <Modal size="lg" opened={opened} onClose={close} title="Add Product">
        <AddProduct setShow={close} />
      </Modal>
    </>
  );
};

export default AddProductButton;
