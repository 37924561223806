import React from "react";
import Screen from "../../components/Screen/Screen";
import AddStaff from "./components/AddStaff";

type Props = {};

const Staff = (props: Props) => {
  return (
    <Screen title="Employees" actionButton={<AddStaff />}>
      Staff
    </Screen>
  );
};

export default Staff;
